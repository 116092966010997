<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Types of Reactions - Prelab Exercise Part 1</h2>

    <p class="mb-6">
      The objective of this exercise is to ensure your familiarity with the names and formulae of
      compounds you will encounter in freshman chemistry. Many of these will already be familiar to
      you from previous courses in chemistry and the exercise will constitute a refresher. Refer to
      Appendix: Nomenclature of Molecular and Inorganic Compounds in your lab manuals for more
      information regarding naming.
    </p>

    <p class="mb-6">Give the correct name for each of the following compounds.</p>

    <v-simple-table style="margin-bottom: 20px">
      <thead>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Molecular Formula}$" />
          </td>
          <td>
            <stemble-latex content="$\textbf{Name of Compound}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="a) KI" />
          </td>
          <td>
            <v-text-field v-model="inputs.kiAnswer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="b) PbO2" />
          </td>
          <td>
            <v-text-field v-model="inputs.pbo2Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="c) FePO4" />
          </td>
          <td>
            <v-text-field v-model="inputs.fepo4Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="d) Ca(ClO2)2" />
          </td>
          <td>
            <v-text-field v-model="inputs.caclo22Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="e) Ag2SO4" />
          </td>
          <td>
            <v-text-field v-model="inputs.ag2so4Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="f) N2O5" />
          </td>
          <td>
            <v-text-field v-model="inputs.n2o5Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="g) NaClO4" />
          </td>
          <td>
            <v-text-field v-model="inputs.naclo4Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="h) HBrO2(aq)" />
          </td>
          <td>
            <v-text-field v-model="inputs.hbro2Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="i) H3PO4(aq)" />
          </td>
          <td>
            <v-text-field v-model="inputs.h3po4Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="j) HBr(aq)" />
          </td>
          <td>
            <v-text-field v-model="inputs.hbrAnswer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="k) MgO" />
          </td>
          <td>
            <v-text-field v-model="inputs.mgoAnswer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="l) CrCl3" />
          </td>
          <td>
            <v-text-field v-model="inputs.crcl3Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="m) CuSO4 * 5 H2O" />
          </td>
          <td>
            <v-text-field v-model="inputs.cuso45h2oAnswer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="n) Ca(MnO4)2" />
          </td>
          <td>
            <v-text-field v-model="inputs.camno42Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="o) NH4CN" />
          </td>
          <td>
            <v-text-field v-model="inputs.nh4cnAnswer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="p) P4O10" />
          </td>
          <td>
            <v-text-field v-model="inputs.p4o10Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="q) NiCO3" />
          </td>
          <td>
            <v-text-field v-model="inputs.nico3Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="r) HNO3(aq)" />
          </td>
          <td>
            <v-text-field v-model="inputs.hno3Answer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="s) HCN(g)" />
          </td>
          <td>
            <v-text-field v-model="inputs.hcnAnswer" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="t) HI(g)" />
          </td>
          <td>
            <v-text-field v-model="inputs.hiAnswer" />
          </td>
        </tr>
      </thead>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'UPEIReactionsPreLab1',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        kiAnswer: null,
        pbo2Answer: null,
        fepo4Answer: null,
        caclo22Answer: null,
        ag2so4Answer: null,
        n2o5Answer: null,
        naclo4Answer: null,
        hbro2Answer: null,
        h3po4Answer: null,
        hbrAnswer: null,
        mgoAnswer: null,
        crcl3Answer: null,
        cuso45h2oAnswer: null,
        camno42Answer: null,
        nh4cnAnswer: null,
        p4o10Answer: null,
        nico3Answer: null,
        hno3Answer: null,
        hcnAnswer: null,
        hiAnswer: null,
      },
    };
  },
};
</script>
